/*-------- Carousel Indiactors -------*/

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		border-radius: 50%;
		background-color: $white-5;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators1 {
	position: absolute;
	right: 0;
	top: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators2 {
	position: absolute;
	right: 10px;
	left: auto;
	margin:0 auto;
	top: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators3 {
	position: absolute;
	right: auto;
	left: 10px;
	margin:0 auto;
	top: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators4 {
	position: absolute;
	right: 10px;
	left: auto;
	margin: 0 auto;
	bottom: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-bottom: 3%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators5 {
	position: absolute;
	right: auto;
	left: 10px;
	margin: 0 auto;
	bottom: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-bottom: 3%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
}

.owl-carousel.owl-drag .owl-item {
	left: 0 !important;
	right: 0;
}

.carousel-indicators5 {
	li::after {
		position: absolute;
		bottom: -10px;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	}
	.active {
		background-color: $white;
	}
}

.carousel-caption {
	position: absolute;
	right: 15%;
	top: 30%;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
}

.carousel-item-background {
	content: "";
	background: $black-5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 5px;
}

.carousel-indicators li {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin: 1px 2px;
	&.active {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin: 1px 2px;
		border: 5px double;
	}
	background: #e2e2e2;
	border: 4px solid $white;
}

.carousel .carousel-control {
	height: 40px;
	width: 40px;
	background: 0 0;
	margin: auto 0;
	border-radius: 50%;
	background: $black-6;
	z-index: 999;
	i {
		font-size: 32px;
		position: absolute;
		top: 1.6rem;
		display: inline-block;
		margin: -18px 0 0 0;
		z-index: 5;
		left: 0;
		right: 0;
		color: $white-9;
		text-shadow: none;
		font-weight: 700;
		z-index: 999;
	}
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: 0.5rem;
	}
}

.owl-carousel {
	.item7-card-img {
		position: relative;
		overflow: hidden;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../images/other/cursor.png), move;
		overflow: hidden;
	}
}

.owl-nav {
	display: block;
}

#small-categories .owl-nav {
	.owl-next,
	.owl-prev {
		top: 58%;
	}
}

.slider .owl-nav {
	.owl-prev {
		left: 15px;
	}
	.owl-next {
		right: 15px;
	}
}

.owl-nav {
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -25px;
		right: -1.5em;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: -25px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons-banner .owl-nav {
	.owl-prev {
		left: 10px;
	}
	.owl-next {
		right: 10px;
	}
}

.owl-carousel {
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#carousel-controls.owl-carousel .owl-item img,
#exzoom {
	width: 100%;
}

:focus {
	outline: 0 !important;
}

.about-carousel.owl-carousel .owl-dots {
	margin: 0 auto;
	text-align: center;
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
}

@media (max-width: 990px) and (min-width: 767px) {
	.carousel-caption {
		top: 10%;
	}
}

.carousel-bg {
	.prev-icon,
	.next-icon {
		background-color: $black-7;
		padding: 5px 15px;
		border-radius: 100px;
		font-size: 20px;
	}
}

.carousel-control {
	&:active,
	&:focus,
	&:hover {
		color: #333;
	}
}

#thumbcarousel {
	.carousel-control-prev {
		left: 0;
		background: $black-5;
	}
	.carousel-control-next {
		right: 0;
		background: $black-5;
	}
}

.owl-carousel-icons-banner {
	.owl-nav button {
		width: 3rem !important;
		height: 3rem !important;
		border-radius: 50%;
		background: rgba(0, 20, 142, 0.5) !important;
		border: 0px solid rgba(0, 20, 142, 0.5) !important;
		color: $white !important;
	}
	&.owl-carousel:hover .owl-nav button {
		background: rgba(0, 20, 142, 0.7) !important;
		border: 0px solid rgba(0, 20, 142, 0.7) !important;
		color: $white !important;
	}
}

.owl-carousel:hover .owl-nav button {
	background: $white !important;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	animation: sonarEffect 1.3s ease-out 75ms;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 1px 2px;
    border: 5px double !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
.carousel{
	.carousel-item{
		img{
			border-radius: 5px;
		}
	}
}
/*-------- Carousel Indiactors -------*/
