.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: $border;
  }
  
  .panel-default > .panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: $background;
	border-color: $border;
  }
  
  .panel-title {
	font-size: 14px;
	margin-bottom: 0;
  
	> a {
	  display: block;
	  padding: 15px;
	  text-decoration: none;
	}
  }
  
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border: 1px solid $border;
  }
  
  a {
	&:active, &:focus, &:hover {
	  outline: 0;
	  text-decoration: none;
	}
  }
  
  .panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: 0 0;
	box-shadow: none;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  
  .panel-group1 {
	> .panel1:first-child .panel-heading1 {
	  border-radius: 4px 4px 0 0;
	}
  
	.panel1 {
	  border-radius: 0;
	}
  }
  
  .panel-body1 {
	padding: 10px;
  }
  
  .panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .panel-group1 .panel1 + .panel1 {
	margin-top: 0;
  }
  
  .panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: #3f3e50;
	padding: 0;
  }
  
  .panel-group1 .panel-body {
	border-top: 1px solid $border;
  }
  
  .panel-title1 a {
	display: block;
	padding: 15px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
  
	&.collapsed {
	  color: #3f3e50;
	}
  }
  
  .panel-body1 {
	background: $white;
  }
  
  .panel1:last-child {
	.panel-body1 {
	  border-radius: 0 0 4px 4px;
	}
  
	.panel-heading1 {
	  border-radius: 0 0 4px 4px;
	  transition: border-radius .3s linear .2s;
  
	  &.active {
		border-radius: 0;
		transition: border-radius linear 0s;
	  }
	}
  }
  
  .panel-title a.accordion-toggle {
	&:before {
	  content: "\f068";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  
	&.collapsed:before {
	  content: "\f067";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  }
  
  .panel-heading1 {
	a {
	  &.collapsed:before {
		content: "\e9af";
		position: absolute;
		font-family: feather !important;
		right: 10px;
		top: 12px;
		font-size: 20px;
		transition: all .5s;
		transform: scale(1);
	  }
  
	  &:before {
		content: "\e994";
		position: absolute;
		font-family: feather !important;
		right: 10px;
		top: 12px;
		font-size: 20px;
		transition: all .5s;
		transform: scale(1);
	  }
	}
  
	&.active a:before {
	  content: ' ';
	  transition: all .5s;
	  transform: scale(0);
	}
  }
  
  .panel-body-landing:hover > .table > tbody > tr > td {
	color: $white !important;
  }
  
  .panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px $color-2 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
  }
  
  .panel-heading-landing-box {
	background: $color-2 !important;
	color: $white !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
  }
  
  .panel-title-landing {
	color: $color-2 !important;
	font-size: 35px;
	font-weight: 700;
  }
  
  .panel-body-landing {
	border: solid 2px $color-2 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
  }
  
  .panel-footer-landing {
	border: solid 2px $color-2 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
  }
  
  .panel.price h3 {
	margin-bottom: 0;
	padding: 20px 0;
  }
  
  .panel-heading {
	padding: 10px 15px;
	background: $white;
  }
  
  .panel.price > .panel-heading {
	padding: 0;
	position: relative;
  }
  
  .price .list-group-item {
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
  }
  
  .panel.price {
	.list-group-item {
	  &:last-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  &:first-child {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	  }
	}
  
	margin-bottom: 1.5rem;
  }
  
  .price {
	.panel-footer {
	  border-bottom: 0;
	  background-color: $white;
	}
  
	&.panel-color > .panel-body {
	  background-color: $white;
	}
  }
  
  .panel-body {
	padding: 15px;
  
	.lead {
	  strong {
		font-size: 40px;
		margin-bottom: 0;
		font-weight: 400;
	  }
  
	  font-size: 20px;
	  margin-bottom: 0;
	  padding: 10px 0;
	}
  }
  
  .panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid $border;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
  }
  
  .panel.price .btn {
	box-shadow: 0 -1px 0 rgba(50, 50, 50, 0.2) inset;
	border: 0;
  }
  
  .expanel-default {
	border: #ddd !important;
  }
  
  .expanel.expanel-primary {
	border-color: $primary1 !important;
  }
  
  .expanel.expanel-success {
	border-color: $success !important;
  }
  
  .expanel.expanel-danger {
	border-color: $danger !important;
  }
  
  .expanel-primary > .expanel-heading {
	color: $white !important;
	background-color: $primary1 !important;
	border-color: $primary1 !important;
  }
  
  .expanel-success > .expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
  }
  
  .expanel-danger > .expanel-heading {
	color: $white !important;
	background-color: $danger !important;
	border-color: $danger !important;
  }
  
  .expanel-warning > .expanel-heading {
	color: $white !important;
	background-color: #ecd938 !important;
	border-color: #ecd938 !important;
  }
  
  .expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
  }
  
  .expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid $border !important;
	border-radius: 4px !important;
	-webkit-box-shadow: 0 1px 1px $black-05 !important;
	box-shadow: 0 1px 1px $black-05 !important;
  }
  
  .expanel-default > .expanel-heading {
	background-color: $background !important;
	border: 1px solid $border !important;
  }
  
  .expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
  }
  
  .expanel-footer {
	padding: 10px 15px !important;
	background-color: $background !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
  }
  
  .expanel-body {
	padding: 15px !important;
  }