.footer-main {
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 1px solid rgba(167, 180, 201, 0.2);
	border-bottom: 1px solid rgba(167, 180, 201, 0.2);
}

footer .border-top {
	border-top: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-main .form-control {
	border: 0 !important;
}

.footer-payments {
	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
	li {
		display: inline-block;
		padding: 0 6px;
		font-size: 20px;
	}
}

.footer-main img {
	width: 80px;
	border: 2px solid $white-1;
	padding: 2px;
	border-radius: 6px;
}

.footer-light {
	background: #f7f7f7;
}

.footer {
	background: $white;
	border-top: 1px solid $border;
	font-size: .875rem;
	padding: 1.25rem 0;
	color: #a7b4c9;
	.social {
		ul li {
			float: left;
			padding: 7px;
			a {
				color: #a7b4c9;
			}
		}
		margin: 0 auto;
	}
	a:not(.btn) {
		color: #1f252d;
	}
}

@media print {
	.footer {
		display: none;
	}
}

.footerimg img {
	width: 35px;
	height: 35px;
}

.footer-main .social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 20px;
	a {
		color: #a7b4c9;
		line-height: 0;
	}
}

footer .social-icons li a {
	color: $white-6;
}

.footer-btn-outline {
	border: 1px solid $white-2;
	color: $white-6;
	background: transparent;
}

.footer-main {
	.payments li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: $white-6;
			line-height: 0;
		}
	}
	h6 {
		font-size: 16px;
		margin-bottom: 1.2rem;
	}
}

footer .border-bottom {
	border-bottom: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-main {
	p {
		color: $white-7;
	}
	a {
		line-height: 2;
		transition: opacity .2s;
		color: $white-6 ;
	}
	&.footer-main1 {
		a {
			color: #797896;
		}
		.form-control {
			border: 1px solid $border !important;
		}
	}
}

.footer-links {
	a {
		color: $white-6 !important;
		position: relative;
		&:first-child:before {
			background: transparent;
		}
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			height: 15px;
			background: $white-15;
			left: -4px;
			z-index: 17;
			display: block;
			top: 11px;
		}
	}
	&.footer-links2 a {
		&:before {
			height: 100%;
			top: 0;
		}
		&:first-child:before {
			background: $white-15;
		}
		&:last-child:after {
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			background: $white-15;
			right: -4px;
			z-index: 17;
			display: block;
			top: 0;
		}
	}
}

@media (max-width: 1200px) {
	.footer-links.footer-links2 a {
		&:before,
		&:last-child:after {
			display: none !important;
		}
	}
}

.footer-light .footer-links a:before {
	background: rgba(25, 24, 31, 0.1);
}

.footer-links a {
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@media (max-width: 992px) {
	.footer-main h6 {
		margin-top: 2rem;
	}
	footer.bg-dark {
		.text-start {
			text-align: center !important;
		}
		.social {
			li {
				float: none;
				text-align: center;
			}
			text-align: center;
		}
	}
	.footer-main .row div:first-child h6 {
		margin-top: 0;
	}
}

footer .support-service {
	border: 1px solid #19181f;
}