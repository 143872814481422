.text-end {
	text-align: right;
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: #3f3e50 !important;
}

.text-muted-dark {
	color: #5f6877 !important;
}

.tracking-tight {
	letter-spacing: -.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: .05em !important;
}

.leading-none {
	line-height: 1 !important;
}

.leading-tight {
	line-height: 1.3 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 2 !important;
}

.text-blue {
	color: #3ba2ff !important;
}

.text-indigo {
	color: $indigo !important;
}

.text-purple {
	color: $purple!important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-pink {
	color: #ff2b88 !important;
}

.text-red {
	color: $danger !important;
}

.text-orange {
	color: #e67605 !important;
}

.text-yellow {
	color: $warning !important;
}

.text-green {
	color: $success !important;
}

.text-teal {
	color: $teal !important;
}

.text-cyan {
	color: #17a2b8 !important;
}

.text-white {
	color: $white !important;
}

.text-gray {
	color: #858d97 !important;
}

.text-gray-dark {
	color: $gray-dark !important;
}

.text-azure {
	color: #00d6e6 !important;
}

.text-lime {
	color: #63e600 !important;
}

.icon i {
	vertical-align: -1px;
}

a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		 :first-child {
			margin-top: 0;
		}
		 :last-child {
			margin-bottom: 0;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1em;
		}
	}
}

.text-shadow1 {
	text-shadow: 0 10px 10px rgba(196, 192, 220, 0.2);
}