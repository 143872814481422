.demo-accordion p:last-child,
.panel-group1 p:last-child {
	margin-bottom: 0;
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all .5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all .5s;
	}
}

#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all .5s;
	}
	&.active a:before {
		transform: rotate(0);
		transition: all .5s;
	}
}

.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}