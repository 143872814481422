*::-ms-backdrop,
.sptb .item-card9-img {
	height: 252px;
}

*::-ms-backdrop,
.sptb .ads-tabs .item-card9-img {
	height: 211px;
}

*::-ms-backdrop,
.sptb .userprof-tab .item-card9-img {
	height: 232px;
}

*::-ms-backdrop,
.sptb.pb .item-user .profile-pic img {
	height: 200px;
}

*::-ms-backdrop,
.sptb.pb #tab-6 .item-card9-img {
	height: 212px;
}

*::-ms-backdrop,
.map-content-width #tab-12 .item-card9-img {
	height: 160px;
}

*::-ms-backdrop,
.map-content-width #tab-11 .item-card9-imgs {
	height: 197px;
}

*::-ms-backdrop,
.map-content-width #tab-11 .item-card9 .leading-tight,
*::-ms-backdrop,
.map-content-width #tab-11 .item-card9 a {
	font-size: 13px;
}

*::-ms-backdrop,
.map-content-width #tab-11 .item-card9 h4 {
	font-size: 15px;
}

@media (max-width: 991px) {
	*::-ms-backdrop,
	.item-card9-img {
		height: 252px !important;
	}
}