.err .about-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	background-position: center;
	border-radius: 2px;
	margin-top: 1.5rem;
}

.login-img {
	background: url(../images/other/login.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
}

hr.divider {
	padding: 0;
	border: none;
	border-top: solid 1px $border;
	color: #333;
	text-align: center;
	margin: .5rem 0;
	&:after {
		content: "OR";
		display: block;
		position: relative;
		top: -1.5em;
		font-size: 11px;
		padding: 0 .25em;
		background: $background;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		z-index: 9;
		left: 0;
		right: 0;
		line-height: 30px;
		margin: 0 auto;
		font-weight: 500;
		border: 1px solid $border;
	}
}

.construction-img {
	background: url(../images/other/construction.jpg);
	background-size: cover;
}

.z-index-10 {
	z-index: 10;
}

or {
	color: #ff0000;
}

input {
	&.error {
		border: 1px solid #ff0000 !important;
	}
	&.valid {
		border: 1px solid #4ecc48 !important;
	}
}

label.error {
	position: absolute;
}

.error-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
	.card {
		background: $white-2;
		box-shadow: 0 0.0625em 0.1875em 0 #076271, 0 0.5em 0 -0.25em $white-3, 0 0.5em 0.1875em -0.25em #086372, 0 1em 0 -0.5em rgba(255, 255, 255, 0.35), 0 1em 0.1875em -0.5em #096675;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: linear-gradient(to right, rgba(15, 117, 255, 0.8), rgba(45, 220, 211, 0.8));
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.constuction-logo {
	border-bottom: 3px solid;
	border-radius: 3px;
}

.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 2px;
}

.construction-image {
	background: url(../images/photos/construction.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;
		background: linear-gradient(-225deg, rgba(72, 1, 255, 0.9) 0, rgba(121, 24, 242, 0.9) 48%, rgba(172, 50, 228, 0.9) 100%);
    		background: rgba(41, 5, 134, 0.88);
	}
	.card {
		background: $white;
	}
}

.construction {
	z-index: 1;
	.btn {
		&.btn-icon {
			text-align: center;
			padding: 0;
			font-size: 16px;
			color: $white-9;
			border-radius: 50%;
			background: $white-2;
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
		}
		border-radius: 20px;
	}
	.form-control {
		border: 1px solid #c4c5c9;
		border-radius: 20px;
		padding-left: 20px;
		width: 60%;
	}
	.input-group-1 {
		margin: 0 auto;
	}
	h3 {
		color: $white;
		font-size: 3.2rem;
		font-weight: 800 !important;
		margin-bottom: .4em !important;
		letter-spacing: 0;
		padding-bottom: 0;
		line-height: 1.2;
	}
	p {
		margin-bottom: 0;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: .06667em;
		color: $white-7;
	}
}



::-ms-backdrop {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	z-index: 1;
	left: 0;
	right: 0;
}



.construction {
    z-index: 1;
}
