.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.rounded {
	border-radius: 3px !important;
}

.rounded7 {
	border-radius: 7px !important;
}

.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.rounded-end {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-start {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}


/*-------- Flex Direction -------*/

.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}

.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}

.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}

.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}

.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}

.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}

.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}

.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}

.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}

.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}

.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}

.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}

.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}

.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}

@media (min-width: 576px) {
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 768px) {
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 992px) {
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 1200px) {
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 500 !important;
}

.font-weight-semibold2 {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: $white !important;
}

.text-success {
	color: $success !important;
}

a.text-success {
	&:focus,
	&:hover {
		color: #3adfab !important;
	}
}

.text-info {
	color: $info !important;
}

a.text-info {
	&:focus,
	&:hover {
		color: #1594ef !important;
	}
}

.text-warning {
	color: $warning !important;
}

a.text-warning {
	&:focus,
	&:hover {
		color: #fa3 !important;
	}
}

.text-danger {
	color: $danger !important;
}

a.text-danger {
	&:focus,
	&:hover {
		color: #fb5b50 !important;
	}
}

.text-light {
	color: #f8f9fa !important;
}

a.text-light {
	&:focus,
	&:hover {
		color: #dae0e5 !important;
	}
}

.text-dark,
.text-body {
	color: #3f3e50 !important;
}

.text-muted {
	color: #797896 !important;
}

.text-black-50 {
	color: $black-5 !important;
}

.text-white-50 {
	color: $white-5 !important;
}

.text-white-80 {
	color: $white-8 !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}


/*-------- Flex Direction -------*/