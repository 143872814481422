.icon-card {
	display: inline-block;
	li {
		float: left;
		font-size: 13px;
		width: 50%;
		margin-bottom: .5rem;
	}
}

.gradient-icon {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.icon-size {
	font-size: 2.5rem !important;
}

.icon-absolute {
	position: absolute;
	display: block;
	bottom: 10px;
	right: 10px;
	padding: 20px 0;
	text-align: center;
	border-radius: 50%;
	width: 70px;
	height: 70px;
}

@media (min-width: 991px) and (max-width: 1024px) {
	.icon-absolute {
		display: none;
	}
}

.icons-list>li {
	-ms-flex: 1 0 4rem;
	flex: 1 0 4rem;
}