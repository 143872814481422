.display-1 {
	font-size: 10.5rem;
	font-weight: 400;
	line-height: 1.1;
}

.display-2 {
	font-size: 4rem;
	font-weight: 300;
	line-height: 1.1;
}

.display-3 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.1;
}

.display-4 {
	font-size: 3rem;
	font-weight: 300;
	line-height: 1.1;
}

.display-5 {
	font-size: 2.4rem;
	font-weight: 300;
	line-height: 1.1;
}

.display-6 {
	font-size: 2.3rem;
	font-weight: 300;
	line-height: 1.1;
}

.display-1 i,
.display-2 i,
.display-3 i,
.display-4 i {
	vertical-align: baseline;
	font-size: .815em;
}