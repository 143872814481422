$background: var(--primary-bg-background);
$color: #29334c;
$color-2: #eef2f9;
$color-3: #efeff5;
$color-4: #2ddcd3;
$border: #e6e6eb;

/*Color variables*/

$primary1: var(--primary-bg-color);
$primary-hover: var(--primary-bg-hover);
$primary-border: var(--primary-bg-border);
$primary-transparent: var(--primary-bg-transparentcolor);
$secondary: var(--secondary-color);
$secondary-hover : var(--secondary-hover);
$secondary-transparent:var(--secondary-transparent);
$secondary-shadow:var(--secondary-shadow);
$gradient-color:linear-gradient(120deg, #0f75ff 60%, var(--secondary) 100%);
$primary-gradient:linear-gradient(87deg,  var(--primary-bg-color), var(--primary-10) 100%);
$gradient       :linear-gradient(87deg, var(--primary-09), var(--primary-10) 100%) ;
$primary-1:var(--primary-01);
$primary-09:var(--primary-09);
$primary-10:var(--primary-10);
$primary-02:var(--primary-02);
$primary-04:var(--primary-04);
$info: #0ab2e6;
$success: #10d403;
$warning: #ffa22b;
$danger: #d60f02;
$blue: #467fcf;
$teal: #05e6e6;
$pink:#e91e63;
$purple: #604dd8;
$gray-dark: #343a40;
$orange: #e67605;
$indigo: #6574cd;
$white: #fff;
$white1: #f9faff;
$black: #000;
$shadow-1  :rgba(105, 99, 255, 0.25);

/***********dark-theme**************/

$dark-mode: var(--dark-color);
$dark-bg: var(--dark-color2);
$dark-border: rgba(255,255,255,0.2);
$dark-color:#fff;
$dark-shadow:#1c273c1a;
$dark-shadow2:#616366;

/***********transparent-theme**************/

$transparent-mode: var(--transparent-color);
$transparent-bg: var(--transparent-color2);
$transparent-border: rgba(255,255,255,0.2);
$transparent-color:#fff;
$transparent-shadow:#1c273c1a;

/*white variables*/

$white-1: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-5: rgba(255, 255, 255, 0.5);
$white-6: rgba(255, 255, 255, 0.6);
$white-7: rgba(255, 255, 255, 0.7);
$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 0.9);
$white-05: rgba(255, 255, 255, 0.05);
$white-08: rgba(255, 255, 255, 0.08);
$white-15: rgba(255, 255, 255, 0.15);
$white-75: rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$black-5: rgba(0, 0, 0, 0.5);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0, 0, 0, 0.7);
$black-8: rgba(0, 0, 0, 0.8);
$black-9: rgba(0, 0, 0, 0.9);
$black-05: rgba(0, 0, 0, 0.05);